main img {
    width: 600px;
}
.site-title {
    font-size: 1.4rem;
    font-weight: 700;
    /*font-family: "Libre Franklin", sans-serif*/
    font-family: monospace;
}

.subtitle {
    color: #3d3d3d;
    line-height: 0em;
    font-size: 1.2rem;
    font-weight:100;
}

.site-header {
    padding-left: 15%;
    margin:0px auto
}

.container a {
    color: #111;
    font-weight:bold;
    text-decoration: none
}

h1 {
    size: 100%;
    margin:0px
}

header {
    margin: 0 auto;
    padding: 1.5rem;
    margin-bottom: 3rem;
    background-color: white;
    border-bottom: 1px solid #e6e6e6;
    box-shadow: 0 4px 8px -6px grey;
    background-image: url("/assets/images/illa_h.jpg");
    /*background-image: url("/assets/images/escher4.jpg");*/
    background-size: contain; /*500px*/
    background-position-y: center;
    background-position-x: center;
    background-repeat:no-repeat;
    opacity: 0.75;
}