html {
  color: black;
  background-color: white;
  font-family: monospace;
  font-size: 1.3rem;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
}

h2, h3, h4, h5, h6 { margin-top: 3rem; }

hr { margin: 2rem 0; }

p { margin: 1rem 0; }

li { margin: 0.4rem 0; }

*:target { background-color: yellow; }

.wrapper {
  max-width: 60ch;
  margin: 4rem auto;
  padding: 0 1rem;
}

hr {
  text-align: center;
  border: 0;

  &:before { content: '/////' }
  &:after { content: attr(data-content) '/////' }
}

table, th, td {
  width: 100%;
  border: thin solid black;
  border-collapse: collapse;
  padding: 0.4rem;
}

code {
  color: white;
  background-color: black;
}

div.highlighter-rouge code {
  display: block;
  overflow-x: auto;
  padding: 1rem;
}

blockquote {
  font-style: italic;
  border: thin solid black;
  padding: 1rem;

  p { margin: 0; }
}